import React from "react";

import { Divider, Empty, Typography, Button, Tooltip } from "antd";

import cls from "./BookDetail.module.scss";
import { BLACK_SHEET, FIELD_NAMES } from "../../../../lib/const";

const { Title } = Typography;

export const BookDetail = ({ bookData, onChange }) => {
  function renderTree(obj) {
    const treeData = [];

    Object.entries(obj).forEach(([prop, value]) => {
      if (!BLACK_SHEET.includes(prop)) {
        let displayValue;

        switch (true) {
          case typeof value === "object" && value !== null:
            displayValue = renderTree(value);
            break;
          case typeof value === "string":
            displayValue = `${value}`;
            break;
          case value === null:
            displayValue = "-";
            break;
          default:
            displayValue = `${value}`;
        }

        treeData.push(
          <div key={prop} className={cls.content}>
            {isNaN(prop) && (
              <strong>
                {prop in FIELD_NAMES ? FIELD_NAMES[prop] : prop}:{" "}
              </strong>
            )}
            {displayValue}
          </div>
        );
      }
    });

    return treeData;
  }

  return bookData ? (
    <div className={cls.bookDetail}>
      <Title level={4}>{bookData?.name}</Title>
      <Divider />
      {renderTree(bookData)}
      <div className={cls.footer}>
        <Tooltip
          placement="topRight"
          title="Перейти к разметке дидактических единиц"
        >
          <Button type="primary" size="large" onClick={onChange}>
            Далее
          </Button>
        </Tooltip>
      </div>
    </div>
  ) : (
    <Empty description="Информация отсутствует" />
  );
};
