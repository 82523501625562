/**
 *
 */
export function decOfNum(number, dictionary) {
  let decCache = [];
  const decCases = [2, 0, 1, 1, 1, 2];
  if (!decCache[number])
    decCache[number] =
      number % 100 > 4 && number % 100 < 20
        ? 2
        : decCases[Math.min(number % 10, 5)];
  return `${number} ${dictionary[decCache[number]]}`;
}

export const _copyToClipboard = async (text) => {
  try {
    let input = document.createElement("input");
    input.setAttribute("value", text);
    document.body.appendChild(input);
    input.select();
    let result = document.execCommand("copy");
    document.body.removeChild(input);
    return result;
  } catch (err) {
    console.error("Failed to copy: ", err);
    return false;
  }
};

export const transformErrors = (error) => {
  return error.inner.reduce((acc, err) => {
    acc[err.path] = err.message;
    return acc;
  }, {});
};

export const authorsToString = (authors = []) => {
  const arrayAuthors = authors.map((author) =>
    [author.firstName, author.lastName].join(" ").trim()
  );

  return arrayAuthors.join(", ");
};
