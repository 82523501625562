import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { keycloak } from "./app/keycloak/keycloak";
import { client } from "./app/apollo/client_apollo";
import { ApolloProvider } from "@apollo/client";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { BrowserRouter } from "react-router-dom";
import "./assets/styles/index.scss";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    autoRefreshToken
    LoadingComponent={<div>Loading...</div>}
  >
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </ReactKeycloakProvider>
);
