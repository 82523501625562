import React, { useState } from "react";
import { Button, Modal, Tooltip } from "antd";
import { EyeOutlined } from "@ant-design/icons";

import { BookDetail } from "../BookDetail/ui/BookDetail";
import { useSyncFindOneQuery } from "../../../graphql/generated/graphql_neb_sync";

const BookDetailModal = (props) => {
  const { visible, syncId, onCancel } = props;

  console.log(syncId);

  const {
    loading,
    error,
    data: book,
  } = useSyncFindOneQuery({
    variables: { syncFindOneId: syncId },
    fetchPolicy: "network-only",
  });

  return (
    <Modal
      width={860}
      title={false}
      open={visible}
      maskClosable={false}
      onCancel={onCancel}
      footer={false}
    >
      {loading ? (
        <div>loading</div>
      ) : error ? (
        <div>error</div>
      ) : (
        <BookDetail bookData={JSON.parse(book?.syncFindOne.dataBook)} />
      )}
    </Modal>
  );
};

const ButtonDetail = (props) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  return (
    <>
      <Tooltip
        placement="bottomRight"
        title="Смотреть детальную информацию о книге"
      >
        <Button type="dashed" icon={<EyeOutlined />} onClick={showModal} />
      </Tooltip>
      {visible && (
        <BookDetailModal {...props} visible={visible} onCancel={hideModal} />
      )}
    </>
  );
};

export default ButtonDetail;
