import React, { useState, useEffect } from "react";
import { Avatar, Button, Row, Space, Table, Tooltip, Typography } from "antd";
import { ForkOutlined, RedoOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";

import { BookDifference } from "../../../../components/Mesh/BookDifference";

import cls from "./ChangeMeshPage.module.scss";
import {
  useSyncGetChangeListQuery,
  useSyncUpdateMutation,
} from "../../../../graphql/generated/graphql_neb_sync";
import { authorsToString } from "../../../../lib/utils";

const { Title } = Typography;

const ChangeMeshPage = () => {
  const [jsonChangeList, setJsonChangeList] = useState();

  const [handleSyncUpdate] = useSyncUpdateMutation();
  const { loading, error, data, refetch } = useSyncGetChangeListQuery();

  const syncUpdate = ({ bookUuid, syncId, newDataBook }) => {
    handleSyncUpdate({
      variables: {
        body: {
          bookUuid,
          dataBook: JSON.stringify(newDataBook),
        },
        syncUpdateId: syncId,
      },
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            syncGetChangeList: (currentValue = []) => {
              return currentValue.filter((item) => item.id !== syncId);
            },
          },
        });
      },
    });
  };

  useEffect(() => {
    if (data?.syncGetChangeList) {
      const jsonData = data?.syncGetChangeList.map((item) => {
        return {
          ...item,
          dataBook: JSON.parse(item.dataBook),
        };
      });

      setJsonChangeList(jsonData);
    }
  }, [data]);

  if (error) return <div>error</div>;
  return (
    <>
      <Row justify="space-between" className={cls.header}>
        <Title level={4}>Список книг с изменениями</Title>
        <Space>
          <Tooltip
            placement="topRight"
            title="Принудительно получить книги из каталога НЭБ в которых произошли изменения"
          >
            <Button
              icon={<RedoOutlined />}
              loading={loading}
              onClick={() => refetch()}
            >
              Обновить данные
            </Button>
          </Tooltip>
        </Space>
      </Row>
      <Table
        size="middle"
        rowKey={(record) => record.id}
        loading={loading}
        dataSource={jsonChangeList}
      >
        <Column
          width={50}
          render={(_, record) =>
            record.dataBook.image && (
              <Avatar shape="square" src={record.dataBook.image} />
            )
          }
        />

        <Column
          title="Название книги"
          render={(_, record) => (
            <div>
              <div>{record.dataBook.name}</div>
              <div className={cls.authors}>
                {authorsToString(record.dataBook.authors)}
              </div>
            </div>
          )}
        />

        <Column
          width={260}
          title="Внешний идентификатор"
          dataIndex="externalUuid"
        />

        <Column
          dataIndex="action"
          width={180}
          align="right"
          render={(_, record) => (
            <Space>
              <BookDifference
                bookUuid={record.dataBook.uuid}
                syncId={record.id}
                btnAction={<Button type="dashed" icon={<ForkOutlined />} />}
                onChange={(dataBook) =>
                  syncUpdate({
                    bookUuid: record.dataBook.uuid,
                    syncId: record.id,
                    newDataBook: dataBook,
                  })
                }
              />
            </Space>
          )}
        />
      </Table>
    </>
  );
};

export default ChangeMeshPage;
