import React from "react";

import { Tabs, TabsProps } from "antd";
import ChangeMeshPage from "../ChangeMeshPage/ChangeMeshPage";
import ListMeshBookPage from "../ListMeshBookPage/ListMeshBookPage";

const items: TabsProps["items"] = [
  {
    key: "1",
    label: `Каталог книг в МЭШ`,
    children: <ListMeshBookPage />,
  },
  {
    key: "2",
    label: `Книги в которых произошли изменения`,
    children: <ChangeMeshPage />,
  },
];

export const MeshPage = () => {
  return <Tabs defaultActiveKey="1" items={items} />;
};

export default MeshPage;
