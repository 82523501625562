import React, {Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import {routeConfig} from "../config/routeConfig";

const AppRouter = () => (
  <Routes>
    {Object.values(routeConfig).map(({element, path}) => (
      <Route key={path}
             path={path}
             element={(
               <Suspense fallback={<div>Loading</div>}>
                 <main>
                   {element}
                 </main>
               </Suspense>
             )}
      />
    ))}
  </Routes>

);

export default AppRouter;
