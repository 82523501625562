import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AudioBooksEntity = {
  __typename?: 'AudioBooksEntity';
  book?: Maybe<BookEntity>;
  file: Scalars['String'];
  title: Scalars['String'];
  uuid: Scalars['ID'];
};

export type AuthorAliasEntity = {
  __typename?: 'AuthorAliasEntity';
  alias: Scalars['String'];
  author: AuthorEntity;
  uuid: Scalars['ID'];
};

export type AuthorEntity = {
  __typename?: 'AuthorEntity';
  alias?: Maybe<Array<AuthorAliasEntity>>;
  annotation?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  deathday?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
};

export type BookEntity = {
  __typename?: 'BookEntity';
  ageLimit?: Maybe<Scalars['String']>;
  annotation: Scalars['String'];
  audioBooks?: Maybe<Array<AudioBooksEntity>>;
  authors?: Maybe<Array<AuthorEntity>>;
  bbk?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<CategoryEntity>>;
  deepLink?: Maybe<Scalars['String']>;
  epub?: Maybe<Scalars['String']>;
  image: Scalars['String'];
  isActive: Scalars['Boolean'];
  isbn?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  publishers?: Maybe<Array<PublisherEntity>>;
  rights?: Maybe<Array<RightEntity>>;
  sections?: Maybe<Array<BookSectionEntity>>;
  slug: Scalars['String'];
  tags?: Maybe<Array<TagEntity>>;
  uuid: Scalars['ID'];
};

export type BookGetChangeInput = {
  dataBookUpdate: Scalars['String'];
  id: Scalars['Float'];
};

export type BookLiteEntity = {
  __typename?: 'BookLiteEntity';
  annotation: Scalars['String'];
  authors?: Maybe<Array<AuthorEntity>>;
  image: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  tags?: Maybe<Array<TagEntity>>;
  uuid: Scalars['ID'];
};

export type BookLitePaginationResponse = {
  __typename?: 'BookLitePaginationResponse';
  count: Scalars['Float'];
  data: Array<BookLiteEntity>;
};

export type BookSectionEntity = {
  __typename?: 'BookSectionEntity';
  book: BookEntity;
  href?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  part?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
};

export type BookSynchronization = {
  __typename?: 'BookSynchronization';
  bookUuid: Scalars['String'];
  dataBook?: Maybe<Scalars['String']>;
  dataBookUpdate: Scalars['Boolean'];
  externalUuid: Scalars['String'];
  id: Scalars['Float'];
};

export type BookSynchronizationData = {
  __typename?: 'BookSynchronizationData';
  dif?: Maybe<Scalars['String']>;
  new?: Maybe<Scalars['String']>;
  old: Scalars['String'];
};

export type CategoryEntity = {
  __typename?: 'CategoryEntity';
  category: Scalars['String'];
  slug: Scalars['String'];
  uuid: Scalars['ID'];
};

export type CreateBookSynchronizationInput = {
  bookUuid: Scalars['String'];
  dataBook: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  syncCreate: BookSynchronization;
  syncRemove: BookSynchronization;
  syncUpdate: BookSynchronization;
};


export type MutationSyncCreateArgs = {
  body: CreateBookSynchronizationInput;
};


export type MutationSyncRemoveArgs = {
  id: Scalars['Int'];
};


export type MutationSyncUpdateArgs = {
  body: UpdateBookSynchronizationInput;
  id: Scalars['Int'];
};

export type Playlist = {
  __typename?: 'Playlist';
  books: Array<BookEntity>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPublish: Scalars['Boolean'];
  onModeration: Scalars['Boolean'];
  preview?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  userUuid?: Maybe<Scalars['String']>;
};

export type PublisherEntity = {
  __typename?: 'PublisherEntity';
  address: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  site: Scalars['String'];
  uuid: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  findOneBook: BookEntity;
  getAllBook: BookLitePaginationResponse;
  syncFindAll: Array<BookSynchronization>;
  syncFindOne: BookSynchronization;
  syncGetChange: BookSynchronizationData;
  syncGetChangeList: Array<BookSynchronization>;
};


export type QueryFindOneBookArgs = {
  uuid: Scalars['String'];
};


export type QueryGetAllBookArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
};


export type QuerySyncFindOneArgs = {
  id: Scalars['Int'];
};


export type QuerySyncGetChangeArgs = {
  body: BookGetChangeInput;
};

export type RightEntity = {
  __typename?: 'RightEntity';
  data: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['Boolean'];
  uuid: Scalars['ID'];
};

export type TagEntity = {
  __typename?: 'TagEntity';
  slug: Scalars['String'];
  tag: Scalars['String'];
  uuid: Scalars['ID'];
};

export type Test = {
  __typename?: 'Test';
  book: BookEntity;
  id: Scalars['ID'];
  isPublish: Scalars['Boolean'];
  testQuestions: Array<TestQuestion>;
};

export type TestAnswer = {
  __typename?: 'TestAnswer';
  content: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isCorrect: Scalars['Boolean'];
  testQuestionId: Scalars['Float'];
};

export type TestQuestion = {
  __typename?: 'TestQuestion';
  id: Scalars['ID'];
  question: Scalars['String'];
  testId: Scalars['Float'];
  testQuestionAnswer: Array<TestAnswer>;
};

export type UpdateBookSynchronizationInput = {
  bookUuid?: InputMaybe<Scalars['String']>;
  dataBook?: InputMaybe<Scalars['String']>;
};

export type GetAllBookQueryVariables = Exact<{
  title?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
}>;


export type GetAllBookQuery = { __typename?: 'Query', getAllBook: { __typename?: 'BookLitePaginationResponse', count: number, data: Array<{ __typename?: 'BookLiteEntity', uuid: string, name: string, image: string, annotation: string, authors?: Array<{ __typename?: 'AuthorEntity', lastName: string, firstName?: string | null, patronymic?: string | null }> | null }> } };

export type FindOneBookQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type FindOneBookQuery = { __typename?: 'Query', findOneBook: { __typename?: 'BookEntity', ageLimit?: string | null, annotation: string, bbk?: string | null, deepLink?: string | null, epub?: string | null, image: string, isActive: boolean, isbn?: string | null, name: string, price?: number | null, slug: string, uuid: string, audioBooks?: Array<{ __typename?: 'AudioBooksEntity', file: string, title: string, uuid: string }> | null, authors?: Array<{ __typename?: 'AuthorEntity', birthday?: string | null, deathday?: string | null, firstName?: string | null, image?: string | null, lastName: string, patronymic?: string | null, uuid: string, alias?: Array<{ __typename?: 'AuthorAliasEntity', alias: string, uuid: string }> | null }> | null, categories?: Array<{ __typename?: 'CategoryEntity', category: string, slug: string, uuid: string }> | null, publishers?: Array<{ __typename?: 'PublisherEntity', address: string, email: string, name: string, phone: string, site: string, uuid: string }> | null, rights?: Array<{ __typename?: 'RightEntity', data: string, name: string, type: boolean, uuid: string }> | null, sections?: Array<{ __typename?: 'BookSectionEntity', href?: string | null, level?: number | null, offset?: number | null, part?: string | null, path?: string | null, text?: string | null, title?: string | null, order?: number | null, uuid: string }> | null, tags?: Array<{ __typename?: 'TagEntity', slug: string, tag: string, uuid: string }> | null } };

export type SyncCreateMutationVariables = Exact<{
  body: CreateBookSynchronizationInput;
}>;


export type SyncCreateMutation = { __typename?: 'Mutation', syncCreate: { __typename?: 'BookSynchronization', bookUuid: string, dataBook?: string | null, dataBookUpdate: boolean, id: number } };

export type SyncUpdateMutationVariables = Exact<{
  body: UpdateBookSynchronizationInput;
  syncUpdateId: Scalars['Int'];
}>;


export type SyncUpdateMutation = { __typename?: 'Mutation', syncUpdate: { __typename?: 'BookSynchronization', bookUuid: string, dataBook?: string | null, dataBookUpdate: boolean, id: number } };

export type SyncRemoveMutationVariables = Exact<{
  syncRemoveId: Scalars['Int'];
}>;


export type SyncRemoveMutation = { __typename?: 'Mutation', syncRemove: { __typename?: 'BookSynchronization', id: number } };

export type SyncFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type SyncFindAllQuery = { __typename?: 'Query', syncFindAll: Array<{ __typename?: 'BookSynchronization', bookUuid: string, dataBook?: string | null, dataBookUpdate: boolean, externalUuid: string, id: number }> };

export type SyncFindOneQueryVariables = Exact<{
  syncFindOneId: Scalars['Int'];
}>;


export type SyncFindOneQuery = { __typename?: 'Query', syncFindOne: { __typename?: 'BookSynchronization', bookUuid: string, dataBook?: string | null, dataBookUpdate: boolean, externalUuid: string, id: number } };

export type SyncGetChangeQueryVariables = Exact<{
  body: BookGetChangeInput;
}>;


export type SyncGetChangeQuery = { __typename?: 'Query', syncGetChange: { __typename?: 'BookSynchronizationData', dif?: string | null, new?: string | null, old: string } };

export type SyncGetChangeListQueryVariables = Exact<{ [key: string]: never; }>;


export type SyncGetChangeListQuery = { __typename?: 'Query', syncGetChangeList: Array<{ __typename?: 'BookSynchronization', bookUuid: string, dataBook?: string | null, dataBookUpdate: boolean, externalUuid: string, id: number }> };


export const GetAllBookDocument = gql`
    query GetAllBook($title: String, $limit: Float, $page: Float) @api(name: neb_sync) {
  getAllBook(title: $title, limit: $limit, page: $page) {
    count
    data {
      uuid
      name
      image
      annotation
      authors {
        lastName
        firstName
        patronymic
      }
    }
  }
}
    `;

/**
 * __useGetAllBookQuery__
 *
 * To run a query within a React component, call `useGetAllBookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBookQuery({
 *   variables: {
 *      title: // value for 'title'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetAllBookQuery(baseOptions?: Apollo.QueryHookOptions<GetAllBookQuery, GetAllBookQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBookQuery, GetAllBookQueryVariables>(GetAllBookDocument, options);
      }
export function useGetAllBookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBookQuery, GetAllBookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBookQuery, GetAllBookQueryVariables>(GetAllBookDocument, options);
        }
export type GetAllBookQueryHookResult = ReturnType<typeof useGetAllBookQuery>;
export type GetAllBookLazyQueryHookResult = ReturnType<typeof useGetAllBookLazyQuery>;
export type GetAllBookQueryResult = Apollo.QueryResult<GetAllBookQuery, GetAllBookQueryVariables>;
export const FindOneBookDocument = gql`
    query FindOneBook($uuid: String!) @api(name: neb_sync) {
  findOneBook(uuid: $uuid) {
    ageLimit
    annotation
    bbk
    deepLink
    epub
    image
    isActive
    isbn
    name
    price
    slug
    uuid
    audioBooks {
      file
      title
      uuid
    }
    authors {
      alias {
        alias
        uuid
      }
      birthday
      deathday
      firstName
      image
      lastName
      patronymic
      uuid
    }
    categories {
      category
      slug
      uuid
    }
    publishers {
      address
      email
      name
      phone
      site
      uuid
    }
    rights {
      data
      name
      type
      uuid
    }
    sections {
      href
      level
      offset
      part
      path
      text
      title
      order
      uuid
    }
    tags {
      slug
      tag
      uuid
    }
  }
}
    `;

/**
 * __useFindOneBookQuery__
 *
 * To run a query within a React component, call `useFindOneBookQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneBookQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useFindOneBookQuery(baseOptions: Apollo.QueryHookOptions<FindOneBookQuery, FindOneBookQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOneBookQuery, FindOneBookQueryVariables>(FindOneBookDocument, options);
      }
export function useFindOneBookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOneBookQuery, FindOneBookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOneBookQuery, FindOneBookQueryVariables>(FindOneBookDocument, options);
        }
export type FindOneBookQueryHookResult = ReturnType<typeof useFindOneBookQuery>;
export type FindOneBookLazyQueryHookResult = ReturnType<typeof useFindOneBookLazyQuery>;
export type FindOneBookQueryResult = Apollo.QueryResult<FindOneBookQuery, FindOneBookQueryVariables>;
export const SyncCreateDocument = gql`
    mutation SyncCreate($body: CreateBookSynchronizationInput!) @api(name: neb_sync) {
  syncCreate(body: $body) {
    bookUuid
    dataBook
    dataBookUpdate
    id
  }
}
    `;
export type SyncCreateMutationFn = Apollo.MutationFunction<SyncCreateMutation, SyncCreateMutationVariables>;

/**
 * __useSyncCreateMutation__
 *
 * To run a mutation, you first call `useSyncCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncCreateMutation, { data, loading, error }] = useSyncCreateMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSyncCreateMutation(baseOptions?: Apollo.MutationHookOptions<SyncCreateMutation, SyncCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncCreateMutation, SyncCreateMutationVariables>(SyncCreateDocument, options);
      }
export type SyncCreateMutationHookResult = ReturnType<typeof useSyncCreateMutation>;
export type SyncCreateMutationResult = Apollo.MutationResult<SyncCreateMutation>;
export type SyncCreateMutationOptions = Apollo.BaseMutationOptions<SyncCreateMutation, SyncCreateMutationVariables>;
export const SyncUpdateDocument = gql`
    mutation SyncUpdate($body: UpdateBookSynchronizationInput!, $syncUpdateId: Int!) @api(name: neb_sync) {
  syncUpdate(body: $body, id: $syncUpdateId) {
    bookUuid
    dataBook
    dataBookUpdate
    id
  }
}
    `;
export type SyncUpdateMutationFn = Apollo.MutationFunction<SyncUpdateMutation, SyncUpdateMutationVariables>;

/**
 * __useSyncUpdateMutation__
 *
 * To run a mutation, you first call `useSyncUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncUpdateMutation, { data, loading, error }] = useSyncUpdateMutation({
 *   variables: {
 *      body: // value for 'body'
 *      syncUpdateId: // value for 'syncUpdateId'
 *   },
 * });
 */
export function useSyncUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SyncUpdateMutation, SyncUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncUpdateMutation, SyncUpdateMutationVariables>(SyncUpdateDocument, options);
      }
export type SyncUpdateMutationHookResult = ReturnType<typeof useSyncUpdateMutation>;
export type SyncUpdateMutationResult = Apollo.MutationResult<SyncUpdateMutation>;
export type SyncUpdateMutationOptions = Apollo.BaseMutationOptions<SyncUpdateMutation, SyncUpdateMutationVariables>;
export const SyncRemoveDocument = gql`
    mutation SyncRemove($syncRemoveId: Int!) @api(name: neb_sync) {
  syncRemove(id: $syncRemoveId) {
    id
  }
}
    `;
export type SyncRemoveMutationFn = Apollo.MutationFunction<SyncRemoveMutation, SyncRemoveMutationVariables>;

/**
 * __useSyncRemoveMutation__
 *
 * To run a mutation, you first call `useSyncRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncRemoveMutation, { data, loading, error }] = useSyncRemoveMutation({
 *   variables: {
 *      syncRemoveId: // value for 'syncRemoveId'
 *   },
 * });
 */
export function useSyncRemoveMutation(baseOptions?: Apollo.MutationHookOptions<SyncRemoveMutation, SyncRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncRemoveMutation, SyncRemoveMutationVariables>(SyncRemoveDocument, options);
      }
export type SyncRemoveMutationHookResult = ReturnType<typeof useSyncRemoveMutation>;
export type SyncRemoveMutationResult = Apollo.MutationResult<SyncRemoveMutation>;
export type SyncRemoveMutationOptions = Apollo.BaseMutationOptions<SyncRemoveMutation, SyncRemoveMutationVariables>;
export const SyncFindAllDocument = gql`
    query SyncFindAll @api(name: neb_sync) {
  syncFindAll {
    bookUuid
    dataBook
    dataBookUpdate
    externalUuid
    id
  }
}
    `;

/**
 * __useSyncFindAllQuery__
 *
 * To run a query within a React component, call `useSyncFindAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyncFindAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyncFindAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useSyncFindAllQuery(baseOptions?: Apollo.QueryHookOptions<SyncFindAllQuery, SyncFindAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SyncFindAllQuery, SyncFindAllQueryVariables>(SyncFindAllDocument, options);
      }
export function useSyncFindAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SyncFindAllQuery, SyncFindAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SyncFindAllQuery, SyncFindAllQueryVariables>(SyncFindAllDocument, options);
        }
export type SyncFindAllQueryHookResult = ReturnType<typeof useSyncFindAllQuery>;
export type SyncFindAllLazyQueryHookResult = ReturnType<typeof useSyncFindAllLazyQuery>;
export type SyncFindAllQueryResult = Apollo.QueryResult<SyncFindAllQuery, SyncFindAllQueryVariables>;
export const SyncFindOneDocument = gql`
    query SyncFindOne($syncFindOneId: Int!) @api(name: neb_sync) {
  syncFindOne(id: $syncFindOneId) {
    bookUuid
    dataBook
    dataBookUpdate
    externalUuid
    id
  }
}
    `;

/**
 * __useSyncFindOneQuery__
 *
 * To run a query within a React component, call `useSyncFindOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyncFindOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyncFindOneQuery({
 *   variables: {
 *      syncFindOneId: // value for 'syncFindOneId'
 *   },
 * });
 */
export function useSyncFindOneQuery(baseOptions: Apollo.QueryHookOptions<SyncFindOneQuery, SyncFindOneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SyncFindOneQuery, SyncFindOneQueryVariables>(SyncFindOneDocument, options);
      }
export function useSyncFindOneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SyncFindOneQuery, SyncFindOneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SyncFindOneQuery, SyncFindOneQueryVariables>(SyncFindOneDocument, options);
        }
export type SyncFindOneQueryHookResult = ReturnType<typeof useSyncFindOneQuery>;
export type SyncFindOneLazyQueryHookResult = ReturnType<typeof useSyncFindOneLazyQuery>;
export type SyncFindOneQueryResult = Apollo.QueryResult<SyncFindOneQuery, SyncFindOneQueryVariables>;
export const SyncGetChangeDocument = gql`
    query SyncGetChange($body: BookGetChangeInput!) @api(name: neb_sync) {
  syncGetChange(body: $body) {
    dif
    new
    old
  }
}
    `;

/**
 * __useSyncGetChangeQuery__
 *
 * To run a query within a React component, call `useSyncGetChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyncGetChangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyncGetChangeQuery({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSyncGetChangeQuery(baseOptions: Apollo.QueryHookOptions<SyncGetChangeQuery, SyncGetChangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SyncGetChangeQuery, SyncGetChangeQueryVariables>(SyncGetChangeDocument, options);
      }
export function useSyncGetChangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SyncGetChangeQuery, SyncGetChangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SyncGetChangeQuery, SyncGetChangeQueryVariables>(SyncGetChangeDocument, options);
        }
export type SyncGetChangeQueryHookResult = ReturnType<typeof useSyncGetChangeQuery>;
export type SyncGetChangeLazyQueryHookResult = ReturnType<typeof useSyncGetChangeLazyQuery>;
export type SyncGetChangeQueryResult = Apollo.QueryResult<SyncGetChangeQuery, SyncGetChangeQueryVariables>;
export const SyncGetChangeListDocument = gql`
    query SyncGetChangeList @api(name: neb_sync) {
  syncGetChangeList {
    bookUuid
    dataBook
    dataBookUpdate
    externalUuid
    id
  }
}
    `;

/**
 * __useSyncGetChangeListQuery__
 *
 * To run a query within a React component, call `useSyncGetChangeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyncGetChangeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyncGetChangeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useSyncGetChangeListQuery(baseOptions?: Apollo.QueryHookOptions<SyncGetChangeListQuery, SyncGetChangeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SyncGetChangeListQuery, SyncGetChangeListQueryVariables>(SyncGetChangeListDocument, options);
      }
export function useSyncGetChangeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SyncGetChangeListQuery, SyncGetChangeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SyncGetChangeListQuery, SyncGetChangeListQueryVariables>(SyncGetChangeListDocument, options);
        }
export type SyncGetChangeListQueryHookResult = ReturnType<typeof useSyncGetChangeListQuery>;
export type SyncGetChangeListLazyQueryHookResult = ReturnType<typeof useSyncGetChangeListLazyQuery>;
export type SyncGetChangeListQueryResult = Apollo.QueryResult<SyncGetChangeListQuery, SyncGetChangeListQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    