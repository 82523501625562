import { RouteProps } from "react-router-dom";
import { MainPage } from "../../../page/MainPage";
import { NotFoundPage } from "../../../page/NotFoundPage";
import { MeshPage } from "../../../page/MeshPage";

export enum AppRoutes {
  MAIN = "main",
  MESH = "mesh",
  NOT_FOUND = "notFound",
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: "/",
  [AppRoutes.MESH]: "/mesh",
  [AppRoutes.NOT_FOUND]: "*",
};

export const routeConfig: Record<AppRoutes, RouteProps> = {
  [AppRoutes.MAIN]: {
    path: RoutePath.main,
    element: <MainPage />,
  },
  [AppRoutes.MESH]: {
    path: RoutePath.mesh,
    element: <MeshPage />,
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath.notFound,
    element: <NotFoundPage />,
  },
};
