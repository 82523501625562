import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { keycloak } from "../keycloak/keycloak";
import { MultiAPILink } from "@habx/apollo-multi-endpoint-link";
import { message } from "antd";

const multiLink = new MultiAPILink({
  httpSuffix: "",
  endpoints: {
    neb_sync: process.env.REACT_APP_BACKEND as string,
  },
  createHttpLink: () => createHttpLink(),
  getContext: (endpoint) => {
    const accessToken = keycloak.token;
    if (accessToken) {
      return {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
    }
    return {};
  },
});

const errorMiddleware = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message: msg, locations, path, extensions }) => {
      message.error(`[GraphQL error] ${msg}`);
    });
  }
  if (networkError) {
    message.error(`[Network error]: ${networkError}`);
  }
});

export const client = new ApolloClient({
  link: from([errorMiddleware, multiLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
    },
  },
});
