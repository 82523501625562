import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Modal,
  notification,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { DeleteOutlined, PlusOutlined, RedoOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import {
  useSyncFindAllQuery,
  useSyncRemoveMutation,
} from "../../../../graphql/generated/graphql_neb_sync";

import cls from "./ListMeshBookPage.module.scss";
import { CatalogBooks } from "../../../../components/Mesh/CatalogBooks";
import ButtonDetail from "../../../../components/Mesh/BookDetailModal/BookDetailModal";
import { authorsToString } from "../../../../lib/utils";

const { Title } = Typography;

const ListMeshBookPage = () => {
  const [visibleModalCatalog, setVisibleModalCatalog] = useState(false);
  const [jsonDataBook = [], setJsonDataBook] = useState();

  const [syncRemove, { loading: syncRemoveLoading }] = useSyncRemoveMutation();
  const onSyncRemove = (syncRemoveId) => {
    syncRemove({
      variables: { syncRemoveId },
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            syncFindAll: (currentValue = [], { readField }) => {
              return currentValue.filter((item) => {
                const id_cache = readField("id", item);
                return id_cache && +id_cache !== syncRemoveId;
              });
            },
          },
        });
      },
    }).then(({ data }) => {
      notification.open({
        message: `Книга удалена из МЭШ`,
      });
    });
  };

  const {
    loading,
    error,
    data: dataFindAll,
    refetch,
  } = useSyncFindAllQuery({ pollInterval: 1000 * 60 });

  useEffect(() => {
    if (dataFindAll?.syncFindAll) {
      const jsonData = dataFindAll?.syncFindAll?.map((item) => {
        return {
          ...item,
          dataBook: JSON.parse(item.dataBook),
        };
      });

      setJsonDataBook(jsonData);
    }
  }, [dataFindAll]);

  if (error) return <div>error</div>;
  return (
    <>
      <Row justify="space-between" className={cls.header}>
        <Title level={4}>Каталог МЭШ</Title>
        <Space>
          <Tooltip
            placement="topRight"
            title="Вы сможете выбрать книгу из каталога НЭБ, разметить ДЕ и отправить книгу в МЭШ"
          >
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setVisibleModalCatalog(true)}
            >
              Добавить книгу в МЭШ
            </Button>
          </Tooltip>
          <Tooltip
            placement="topRight"
            title="Принудительно получить список книг отправленных в МЭШ"
          >
            <Button
              icon={<RedoOutlined />}
              loading={loading}
              onClick={() => refetch()}
            >
              Обновить данные
            </Button>
          </Tooltip>
        </Space>
      </Row>
      <Table
        size="middle"
        rowKey={(record) => record.id}
        loading={loading || syncRemoveLoading}
        dataSource={jsonDataBook}
      >
        <Column
          width={50}
          render={(_, record) =>
            record.dataBook.image && (
              <Avatar shape="square" src={record.dataBook.image} />
            )
          }
        />

        <Column
          title="Название книги"
          render={(_, record) => (
            <div>
              <div>{record.dataBook.name}</div>
              <div className={cls.authors}>
                {authorsToString(record.dataBook.authors)}
              </div>
            </div>
          )}
        />

        <Column
          width={260}
          title="Внешний идентификатор"
          dataIndex="externalUuid"
        />

        <Column
          dataIndex="action"
          width={180}
          align="right"
          render={(_, record) => (
            <Space>
              <ButtonDetail syncId={record.id} />
              <Tooltip placement="bottomRight" title="Удалить книгу из МЭШ.">
                <Button
                  type="primary"
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => {
                    Modal.confirm({
                      title: `Удалить книгу ${record.dataBook.name} из МЭШ?`,
                      okText: "Да",
                      cancelText: "Отмена",
                      okType: "danger",
                      onOk: () => onSyncRemove(record.id),
                    });
                  }}
                >
                  Удалить
                </Button>
              </Tooltip>
            </Space>
          )}
        />
      </Table>

      <Modal
        key="ModalCatalog"
        maskClosable={false}
        width={"85%"}
        open={visibleModalCatalog}
        onCancel={() => setVisibleModalCatalog(false)}
        footer={false}
      >
        <CatalogBooks />
      </Modal>
    </>
  );
};

export default ListMeshBookPage;
